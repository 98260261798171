<!-- layouts/SettingsLayout.vue -->

<template>
  <NuxtLayout name="portoll">
    <template #sidebarContent>
      <PortollSidebar />
    </template>

    <template #mainContent>
      <div class="p-8">
        <h2 class="font-bold mb-8 text-3xl">
          Portoll Settings
        </h2>

        <main class="bg-white shadow-lg shadow-slate-200 flex rounded">
          <div class="max-w-xs w-full h-full flex-col flex py-7 px-3 space-y-1">
            <div
              v-for="link in links"
              :key="link.label"
            >
              <NuxtLink
                class="p-2 flex items-center gap-2 text-sm text-slate-600 font-medium cursor-pointer hover:bg-indigo-300/20 hover:text-brand rounded"
                active-class="bg-indigo-300/20 !text-brand rounded"
                :to="link.to"
              >
                <Icon
                  :name="link.icon.name"
                  class="text-2xl"
                />

                {{ link.label }}
              </NuxtLink>
            </div>
          </div>

          <div class="border-l border-slate-200 w-full">
            <div class="p-8">
              <h2 class="text-2xl font-bold text-slate-800">
                {{ fetchHeading(route.path) }}
              </h2>

              <div class="pb-6">
                <slot name="main-content" />
              </div>
            </div>

            <div class="border-t border-slate-200 p-6 space-x-6 text-end w-full">
              <slot name="footer" />
            </div>
          </div>
        </main>
      </div>
    </template>
  </NuxtLayout>
</template>

<script setup lang="ts">
  const route = useRoute();

  const links = [
    {
      to: "/portoll/settings",
      label: "My Portoll",
      icon: { name: "material-symbols:person" },
    },
    {
      to: "/portoll/settings/notifications",
      label: "Notifications",
      icon: { name: "material-symbols:notifications" },
    },
    {
      to: "/portoll/settings/plans",
      label: "Subscription Plans",
      icon: { name: "material-symbols:sort" },
    },
    {
      to:"/portoll/settings/billing",
      label: "Billing & Invoices",
      icon: { name: "material-symbols:receipt" },
    },
  ];

  const isActive = (to: string) => route.path === to;

  const fetchHeading = (routeName: string) => {
    const activeRoute: any = links.find((link) => link.to === routeName);
    return activeRoute.label;
  };
</script>

